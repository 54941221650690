import React from "react";
import Clock from "assets/clock.svg";

const GlobalSupport = () => {
  return (
    <div className="p-4">
      <div className="max-w-7xl mx-auto sm:px-6 py-12">
        <div className="lg:flex justify-between items-center gap-4">
          <div className="bg-[#e7f0ff] py-14 rounded-lg px-4 md:px-16 lg:w-3/4">
            {" "}
            <h1 className="font-primary leading-[60px] pb-2 font-semibold text-3xl md:text-4xl ">
              Your 24/7 Global Support System <br />
            </h1>
            <p className="font-primary pt-2 font-semibold text-3xl md:text-4xl ">
              Around the Clock
            </p>
            <p className="mt-6 font-opensans font-normal leading-6 text-base md:text-lg">
              At Tomedes, 'office hours' don't apply. We're available 24/7,
              globally. No matter the time or place, count on us for your
              language needs, making your life easier, one project at a time.
            </p>
          </div>
          <div className="lg:mt-0 mt-6 lg:block hidden ">
            <img src={Clock} alt="" className="lg:w-max w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default GlobalSupport;
