import React from "react";
import "swiper/css";
import "swiper/css/grid";

import { Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Avator1 from "assets/avator1.svg";
import Avator2 from "assets/avator2.svg";
import Avator3 from "assets/avator3.svg";
import Avator4 from "assets/avator4.svg";
import Avator5 from "assets/avator5.svg";
import Avator6 from "assets/avator6.svg";
import Avator7 from "assets/avator7.svg";
import Avator8 from "assets/avator8.svg";
import Avator9 from "assets/avator9.svg";
import Avator10 from "assets/avator10.svg";
import Nexticon from "assets/nexticon.svg";
import Pre from "assets/pre.svg";

const Sliderdata = [
  { img: Avator1, name: "Arianne R.", city: "Philippines" },
  { img: Avator2, name: "Ortal L.", city: "Israel" },
  { img: Avator3, name: "Denis C.", city: "Brazil" },
  { img: Avator4, name: "Brenda D.", city: "Philippines" },
  { img: Avator5, name: "Haidelyn D.", city: "Philippines" },
  { img: Avator6, name: "Audrey M.", city: "Philippines" },
  { img: Avator7, name: "Maya R.", city: "Israel" },
  { img: Avator8, name: "Allysa M.", city: "Philippines" },
  { img: Avator9, name: "Keith M.", city: "Philippines" },
  { img: Avator10, name: "Phoebe R.", city: "Philippines" },
];

const CustomerSupport = () => {
  return (
    <div className="py-16 px-4">
      <div className="max-w-7xl mx-auto  sm:px-6 py-12 ">
        <div className="lg:flex justify-between gap-6">
          <div className="text-[#00173a] lg:w-2/5 flex ">
            <div className=" pt-16">
              <h1 className="text-3xl md:text-4xl leading-[60px] font-semibold font-primary">
                Meet Our Friendly Customer Support Team
              </h1>
              <p className="text-base md:text-lg font-normal font-opensans leading-6 mt-4">
                Let us introduce you to some key members of our customer support
                team. With their expertise, experience, and passion for helping
                our clients, you can rest assured you're in good hands.
              </p>
            </div>
          </div>
          <div className="lg:w-3/5 mt-12 lg:mt-0 ">
            <Swiper
              // slidesPerView={4}
              grid={{
                rows: 2,
              }}
              spaceBetween={30}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              style={{
                height: "460px",
                paddingTop: "0px",
                paddingBottom: "80px",
              }}
              className="mySwiper "
              modules={[Grid, Navigation]}
              breakpoints={{
                332: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  // slidesPerGroup: 1,
                  spaceBetween: 5,
                },
                1024: {
                  slidesPerView: 4,
                  // slidesPerGroup: 1,
                  spaceBetween: 30,
                },
              }}
            >
              {Sliderdata.map((slideContent, index) => (
                <SwiperSlide
                  key={slideContent.img}
                  // virtualIndex={index}
                  style={{ height: "140px" }}
                >
                  <div className="relative group flex flex-col items-center justify-center">
                    <img
                      src={slideContent.img}
                      alt=""
                      className="h-[170px] md:w-[170px] w-[130px] object-cover text-center filter grayscale text-white contrast-70 hover:filter-none hover:opacity-100 transition-all duration-300 rounded-lg"
                    />
                    <h3 className="absolute bottom-6 text-base font-semibold text-white font-opensans opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      {slideContent.name}
                    </h3>
                    <p className="absolute bottom-2 text-sm font-normal text-white font-opensans  opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      {slideContent.city}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
              <div className="h-16 flex gap-2 items-center justify-center">
                <div
                  style={{ border: "none", outline: "none" }}
                  className="absolute bottom-[-1.4rem] w-24 h-24  flex items-center justify-center gap-5 "
                >
                  <img
                    style={{ height: "60px", width: "60px" }}
                    src={Nexticon}
                    alt="Next"
                    className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
                  />

                  <img
                    style={{ height: "60px", width: "60px" }}
                    src={Pre}
                    alt="Previous"
                    className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
                  />
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupport;

// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Nexticon from "assets/nexticon.svg";
// import Pre from "assets/pre.svg";
// import Avator1 from "assets/avator1.svg";
// import Avator2 from "assets/avator2.svg";
// import Avator3 from "assets/avator3.svg";
// import Avator4 from "assets/avator4.svg";
// import Avator5 from "assets/avator5.svg";
// import Avator6 from "assets/avator6.svg";
// import Avator7 from "assets/avator7.svg";
// import Avator8 from "assets/avator8.svg";
// import Avator9 from "assets/avator9.svg";
// import Avator10 from "assets/avator10.svg";

// const Sliderdata = [
//   { img: Avator1, name: "Arianne R.", city: "Philippines" },
//   { img: Avator2, name: "Ortal L.", city: "Israel" },
//   { img: Avator3, name: "Denis C.", city: "Brazil" },
//   { img: Avator4, name: "Brenda D.", city: "Philippines" },
//   { img: Avator5, name: "Haidelyn D.", city: "Philippines" },
//   { img: Avator6, name: "Audrey M.", city: "Philippines" },
//   { img: Avator7, name: "Maya R.,", city: "Israel" },
//   { img: Avator8, name: "Allysa M.,", city: "Philippines" },
//   { img: Avator9, name: "Keith M.", city: "Philippines" },
//   { img: Avator10, name: "Phoebe R.", city: "Philippines" },
// ];

// const CustomerSupport = () => {
//   const settings = {
//     slidesToShow: 4,
//     infinite: false,
//     slidesToScroll: 1,
//     rows: 2,
//     nextArrow: <img src={Nexticon} alt="Next" />,
//     prevArrow: <img src={Pre} alt="Previous" />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 640,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="py-16 px-4">
//       <div className="max-w-7xl mx-auto sm:px-6 py-12">
//         <div className="lg:flex justify-between gap-6">
//           <div className="text-[#00173a] lg:w-2/5 flex">
//             <div className="pt-16">
//               <h1 className="text-3xl md:text-4xl leading-[60px] font-semibold font-primary">
//                 Meet Our Friendly Customer Support Team
//               </h1>
//               <p className="text-base md:text-lg font-normal font-opensans leading-6 mt-4">
//                 Let us introduce you to some key members of our customer support
//                 team. With their expertise, experience, and passion for helping
//                 our clients, you can rest assured you're in good hands.
//               </p>
//             </div>
//           </div>
//           <div className="lg:w-3/5 mt-12 lg:mt-0">
//             <Slider {...settings}>
//               {Sliderdata.map((slideContent, index) => (
//                 <div className="group flex flex-col items-center justify-center">
//                   <img
//                     src={slideContent.img}
//                     alt=""
//                     className=" object-cover text-center filter grayscale text-white contrast-70 hover:filter-none hover:opacity-100 transition-all duration-300 rounded-lg"
//                   />
//                   <h3 className="relative bottom-16 text-base text-center font-semibold text-white font-opensans opacity-0 group-hover:opacity-100 transition-opacity duration-300">
//                     {slideContent.name}
//                   </h3>
//                   <p className="relative bottom-14 text-sm text-center font-normal text-white font-opensans  opacity-0 group-hover:opacity-100 transition-opacity duration-300">
//                     {slideContent.city}
//                   </p>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CustomerSupport;
