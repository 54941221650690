import React from "react";
import Hero from "assets/herobanner.svg";
import QualityGurantee from "components/YearGuarantee/quality-gurantee";
import { Link } from "gatsby";
import GlobalSupport from "./globalSupport";
import SupportOffers from "./supportOffers";
import CustomerSupport from "./customerSupport";
import Advocate from "components/YearGuarantee/advocate";
import EffortCom from "./effortlessCom";
import ClientTestimonial from "components/YearGuarantee/clientTestimonial";
import { SupportData, TestimonialData } from "./config";
import ReliableSupport from "./reliableSupport";
const Availabe = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${Hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const buttonContent = (
    <button className="border border-[#ffffff] px-4 py-2 font-opensans rounded-sm w-full md:w-max">
      Contact us
    </button>
  );
  const buttonConstant1 = (
    <button className="bg-[#ff7b16] px-4 py-2 font-opensans rounded-sm w-full md:w-max">
      Get Started
    </button>
  );
  const heading = (
    <h1 className="text-3xl md:text-4xl font-primary font-semibold leading-[60px]">
      Start Your Journey with
      <br /> Reliable Support
    </h1>
  );
  return (
    <>
      <section
        className="px-4  py-12 lg:h-[600px] flex items-center"
        style={backgroundImageStyle}
      >
        <QualityGurantee
          heading=" 24/7 Dedicated Customer Support"
          subheading="In the fast-paced world of language services, having responsive and dependable customer support is paramount. At Tomedes, our global teams ensure 24/7 coverage without premium charges, providing quick responses even during the night - wherever you may be. We're your unwavering support system."
          button={buttonContent}
          button1={buttonConstant1}
        />
      </section>
      <div className="w-11/12 max-w-7xl mx-auto flex flex-col">
        <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 ">
          <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
            <Link to="/">
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
                alt="home"
              />
            </Link>
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
              alt="navigate"
            />
            <Link to="/about-tomedes">
              <span>About</span>
            </Link>
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
              alt="navigate"
            />
            <span>Available 24/7</span>
          </div>
        </div>
      </div>
      <GlobalSupport />
      <SupportOffers />
      <CustomerSupport />
      <div className="py-16">
        <Advocate />
      </div>
      <EffortCom />
      {/* <ClientTestimonial testimoniladata={TestimonialData} /> */}
      <ClientTestimonial
        text="Client Testimonials"
        description=" Discover what our clients have to say about the reliability and
              responsiveness of our customer support. Their stories speak to the
              immense value of having a dedicated support system."
        btntext="See Testimonials"
        testimoniladata={TestimonialData}
        bgClass="bg-white"
      />
      <div className="bg-[#E7F0FF] text-[#00173A]">
        <ReliableSupport
          // heading="Start Your Journey with "
          // text="Reliable Support"
          heading={heading}
          subheading=" We’re honored to be your trusted partner in language services.
                Reach out today, and let us make your life easier."
          supdata={SupportData}
        />
      </div>
    </>
  );
};
export default Availabe;

// import React from "react";
// import "swiper/css";
// import "swiper/css/grid";

// import { Grid, Navigation } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import Avator1 from "assets/avator1.svg";
// import Avator2 from "assets/avator2.svg";
// import Avator3 from "assets/avator3.svg";
// import Avator4 from "assets/avator4.svg";
// import Avator5 from "assets/avator5.svg";
// import Avator6 from "assets/avator6.svg";
// import Avator7 from "assets/avator7.svg";
// import Avator8 from "assets/avator8.svg";
// import Avator9 from "assets/avator9.svg";
// import Avator10 from "assets/avator10.svg";
// import Nexticon from "assets/nexticon.svg";
// import Pre from "assets/pre.svg";

// const Sliderdata = [
//   { img: Avator1, name: "Arianne R.", city: "Philippines" },
//   { img: Avator2, name: "Ortal L.", city: "Israel" },
//   { img: Avator3, name: "Denis C.", city: "Brazil" },
//   { img: Avator4, name: "Brenda D.", city: "Philippines" },
//   { img: Avator5, name: "Haidelyn D.", city: "Philippines" },
//   { img: Avator6, name: "Audrey M.", city: "Philippines" },
//   { img: Avator7, name: "Maya R.", city: "Israel" },
//   { img: Avator8, name: "Allysa M.", city: "Philippines" },
//   { img: Avator9, name: "Keith M.", city: "Philippines" },
//   { img: Avator10, name: "Phoebe R.", city: "Philippines" },
// ];

// const CustomerSupport = () => {
//   return (
//     <div className="py-16 px-4">
//       <div className="max-w-7xl mx-auto  sm:px-6 py-12 ">
//         <div className="lg:flex justify-between gap-6">
//           <div className="text-[#00173a] lg:w-2/5 flex ">
//             <div className=" pt-16">
//               <h1 className="text-3xl md:text-4xl leading-[60px] font-semibold font-primary">
//                 Meet Our Friendly Customer Support Team
//               </h1>
//               <p className="text-base md:text-lg font-normal font-opensans leading-6 mt-4">
//                 Let us introduce you to some key members of our customer support
//                 team. With their expertise, experience, and passion for helping
//                 our clients, you can rest assured you're in good hands.
//               </p>
//             </div>
//           </div>
//           <div className="lg:w-3/5 mt-12 lg:mt-0 ">
//             <Swiper
//               // slidesPerView={4}
//               grid={{
//                 rows: 2,
//               }}
//               spaceBetween={30}
//               // navigation={{
//               //   nextEl: ".swiper-button-next",
//               //   prevEl: ".swiper-button-prev",
//               // }}
//               navigation={true}
//               style={{
//                 height: "460px",
//                 paddingTop: "0px",
//                 paddingBottom: "80px",
//               }}
//               className="mySwiper "
//               modules={[Grid, Navigation]}
//               breakpoints={{
//                 332: {
//                   slidesPerView: 2,
//                   spaceBetween: 10,
//                 },
//                 768: {
//                   slidesPerView: 3,
//                   slidesPerGroup: 1,
//                   spaceBetween: 5,
//                 },
//                 1024: {
//                   slidesPerView: 3,
//                   slidesPerGroup: 1,
//                   spaceBetween: 30,
//                 },
//               }}
//             >
//               {Sliderdata.map((slideContent, index) => (
//                 <SwiperSlide
//                   key={slideContent.img}
//                   // virtualIndex={index}
//                   style={{ height: "140px" }}
//                 >
//                   <div className="relative group flex flex-col items-center justify-center">
//                     <img
//                       src={slideContent.img}
//                       alt=""
//                       className="h-[170px] md:w-[170px] w-[130px] object-cover text-center filter grayscale text-white contrast-70 hover:filter-none hover:opacity-100 transition-all duration-300 rounded-lg"
//                     />
//                     <h3 className="absolute bottom-6 text-base font-semibold text-white font-opensans opacity-0 group-hover:opacity-100 transition-opacity duration-300">
//                       {slideContent.name}
//                     </h3>
//                     <p className="absolute bottom-2 text-sm font-normal text-white font-opensans  opacity-0 group-hover:opacity-100 transition-opacity duration-300">
//                       {slideContent.city}
//                     </p>
//                   </div>
//                 </SwiperSlide>
//               ))}
//               {/* <div className="h-16 flex gap-2 items-center justify-center">
//                 <div
//                   style={{ border: "none", outline: "none" }}
//                   className="absolute bottom-[-1.4rem] w-24 h-24  flex items-center justify-center gap-5 "
//                 >
//                   <img
//                     style={{ height: "60px", width: "60px" }}
//                     src={Nexticon}
//                     alt="Next"
//                     className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
//                   />

//                   <img
//                     style={{ height: "60px", width: "60px" }}
//                     src={Pre}
//                     alt="Previous"
//                     className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
//                   />
//                 </div> */}
//               {/* </div> */}
//             </Swiper>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CustomerSupport;
