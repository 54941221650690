import Availabe from "components/Available24/7";
import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";

const Available24 = () => {
  const description =
    " Get language services whenever, wherever. Our team offers support and translation services 24/7, 365 days a year, ensuring assistance even on holidays.";
  const title = "24/7 Customer Support & Translation";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        slug="/about/available-24/7"
      />
      <Availabe />
    </Layout>
  );
};
export default Available24;
