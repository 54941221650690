import React from "react";
import { Communicationdata } from "./config";
import { Link } from "gatsby";

const EffortCom = () => {
  return (
    <div className="bg-[#e2ebf8] py-16 px-4">
      <div className="max-w-4xl mx-auto p-4 text-[#00173a] sm:px-6 py-12">
        <h1 className="text-center font-primary font-semibold text-3xl md:text-4xl leading-[52px]">
          Effortless Communication, Always
        </h1>
        <p className="text-center text-base md:text-lg font-normal font-opensans leading-6 ">
          No matter your preference, we've got a channel for you. Choose the
          best way for you and reach out. We'll be there, ready to assist:
        </p>
      </div>
      <div className="max-w-7xl mx-auto p-4 text-[#00173a]">
        <div className="flex items-center justify-center">
          <div className="grid md:grid-cols-2 lg:grid-cols-5 items-center justify-center gap-6 ">
            {Communicationdata.map((item, i) => (
              <Link
                to={item.link}
                href={item.link}
                key={i}
                className={`cursor-pointer effort-com-item w-[230px] flex flex-col text-[#00173a] bg-white ${
                  item.description === "WhatsApp"
                    ? "hover:bg-[#05D84A]"
                    : "hover:bg-[#00173a]"
                }   hover:text-white rounded-lg items-center px-6 pt-8 pb-4 `}
              >
                <div>
                  <img src={item.imgUrl} alt="" />
                </div>
                <div className="h-[100px]">
                  <p className="mt-6 text-center font-primary font-semibold leading-7 text-xl">
                    {item.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EffortCom;
