import React from "react";
import { SupportOfferdata } from "./config";
import Icon from "assets/icon.svg";

const SupportOffers = () => {
  return (
    <div className="bg-[#ffefe2] py-16 text-[#00173a] mt-10">
      <div className="max-w-4xl mx-auto p-4">
        <h1 className="font-primary font-semibold text-3xl md:text-4xl leading-[60px] text-center">
          What Our Dedicated Customer Support Offers
        </h1>
        <div className="mt-10 grid lg:grid-cols-2 px-4 gap-8">
          {SupportOfferdata.map((item, i) => (
            <div className="flex gap-6">
              <div className="">
                <img src={Icon} alt="" className="min-w-[28px] mt-2 sm:mt-0" />
              </div>
              <p className="text-lg md:text-xl font-primary font-medium leading-8">
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SupportOffers;
